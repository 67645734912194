.appbar {
    position: fixed;
    width: 100%;
    z-index: 1000;
    background-color: BLACK ;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .appbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1100px; 
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .appbar-logo {
    color: black;
    font-size: 2rem;
    text-decoration: none;
  }
  
  .menu-icon{
    cursor: pointer;
    color: white; 
    font-size: 1.5rem;
  }

  .menu-icon:hover{
    color: tomato;
  }
  
  .nav-menu {
    list-style: none;
    display: flex;
    align-items: center;
    
  }
  

  .app-item {
    margin-right: 5px;
    text-decoration: none;
    color: whitesmoke; 
    font-size: 1rem;
    cursor: pointer;
    letter-spacing: 1.5px;
    font-weight: 500;
    padding:10px;
    transition: color 0.1s ;
    
  }
  .app-item1 {
    margin-right: 5px;
    text-decoration: none;
    color:white; 
    font-size:0.8rem;
    cursor: pointer;
    letter-spacing: 1px;
    font-weight: 500;
    background-color: blue;
    padding:15px;
    transition: color 0.1s ;
    
  }
  .app-item:hover {
    color:tomato;
  }
 
  
 
  @media screen and (max-width: 1000px) {
    .menu-icon {
      display: block;
      color: white;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      position: absolute;
      top: 70px; 
      left: 0;
      background-color: black; 
      text-align: center;
      opacity: 0.7;
      transition: all 0.3s ease-in-out;
      visibility: hidden;
    }
  
    .nav-menu.active {
      visibility: visible;
    }
  
    .app-item {
      margin: 10px 0;
    }
  }
  