section {
    box-sizing: border-box;
    width: 100%;
    height: auto;
    justify-content: flex-start;
    overflow: hidden; 
  } 

  section span {
    display: block;
    transform: translateX(-100px);
    opacity: 0;
  }