  .card-container {
    justify-content: space-between; 
  }
  
  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 350px;
    height: 280px;
    box-sizing: border-box;
    margin: 10px;
  }
  .card:hover{
    background-color: #014aad;
    box-shadow: 10px 8px 16px 0  rgb(107, 181, 231);
  }

  .container {
    text-align: center;
    margin-top: 20px;
  }

  .avatar {
    width: 500;
    height: 500;
  }

 .highlight{
    background-color:#014aad ;
    color: white;
 }

 .card:hover h4,
 .card:hover p {
     color: white;
 }

 .home-h1{
  color: #eee;
  text-align:start;
  font-size:58px;
  font-weight: 400;
 letter-spacing: 1px;
 line-height: 70px;
 }
 
 .home-h1 span i{
  color:#1b7dff;
   letter-spacing:1px
 }
 .div{
  background-color:rgba(0,0,0,0.7);
  padding:100px
 }

 .about-image{
   width:500px;
   min-width:500px;
   max-Width:500px;
   margin-right: 20px;
   box-shadow: -15px -15px 0px  #1b7dff ;
 }

@media only screen and (max-width:569px){
  .div{
    padding:60px
  }
  
  .home-h1{
    font-size: 40px;
    line-height: 50px;
  }
  .about-image{
    width:300px;
    min-width:300px;
    max-Width:300px;
    box-shadow: -15px -15px 0px  #1b7dff ;
  }
}
