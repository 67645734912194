
  
  .contact-title {
    color: black;
    font-size: 3rem;
    
  }
  
  .contact-form-wrapper {
    background: rgba(163, 163, 172, 0.8);
    padding: 10px;
    border-radius: 10px;
  
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
     max-width: 100%; 
    
  }
  
  .contact-form input,
  .contact-form textarea {
    width: 60%;
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 5px;
  }
  
  .contact-form input[type="text"],
  .contact-form input[type="email"],
  .contact-form input[type="number"],
  .contact-form textarea {
    background: rgba(255, 255, 255, 0.9);
    font-size: 1rem;
  }
  
  .contact-form textarea {
    resize: none;
  }
  
  .submit-button {
    background: #235BCB;
    color: white;
    border: none;
    padding: 15px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1rem;
    transition: background 0.3s ease;
  }
  
  .submit-button:hover {
    background:#7793ee;
  }
  


  @media (max-width: 850px) {
    .contact-container {
      flex-direction: column;
      align-items: center;
      height: 100%;
    }
  
    .contact-form-wrapper,
    .reachus {
      max-width: 90%; 
      margin: 10px 0; 
    }
  }
  @media only screen and (max-width:660px){
    .contact-form input,
    .contact-form textarea {
      width: 90%;
  }
}