
.sec{
    justify-content: space-evenly;
}
.sec_img{
    width: 300px;
    max-width: 400px;
    min-width: 300px;
  
}
.sec h3{
   
    font-weight: 400;
    color:#222
}

.sec_p{
    width:400px;
    min-width: 300px;
    color:gray  
}
.sec1{
    justify-content: space-between;
}
.sec1_img{
background-color:#1b7dff;
width:250px;
height:auto;

}

.sec1_p{
    text-align:justify;
    padding:60px;
}
.sec1_p p{
   
    color:gray
}

.slideshow-container {
    overflow: hidden;
  }
 
  .slides {
    display: flex;
    transition: transform 1s ease-in-out;
  }
 
  .slide {
    flex: 0 0 auto;
    margin: 5px;
  }
 
  .slide:last-child {
    margin-right: 0;
  }
 
  .slide img {
    width: 300px;
    height:350px; 
    min-width: 300px;
    max-width: 300px;
  }
  
 
  .step2{
    padding-top: 100px;
  }
  .step4{
    padding-top: 100px;
  }
 
  .step3{
    padding-top: 180px;
  }

  .promss-heading{
    padding-top: 40px;
  }
  .qtns{
    padding:50px;
  }
.review{
  margin-top:50px;
}
.text-size{
  font-size: 50px;
}
@media only screen and (max-width:590px){
    .sec1_img img{
        width:360px
    }
    .text-size{
      font-size: 40px;
    }
    

}
@media only screen and (max-width:750px){
    .step2{
        padding-top: 0;
    }
    .step3{
        padding-top: 0;
    }
    .step4{
        padding-top: 0;
    }
    .promss-heading{
        padding-top:12px
    }
.qtns{
  padding:0px
}
.review{
  margin-top: 0px;
}
.side-btn{
  top:500px
}
}